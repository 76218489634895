
@import "../../../../sass/imports";

.preferences-mismatch-modal {
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
}

.preferences-mismatch-modal-container {
    position: absolute;
    top: 40%;
    left: 50%;
    overflow: hidden;
    @include transform(translate(-50%, -50%));
    min-width: 250px;
    background-color: white;
    max-width: 380px;

    padding: 40px !important;
    text-align: center;
    @include border-radius(8px);

    h1 {
        font-size: 14px;
        font-weight: normal;
        color: $kelly-green;
        margin: 1em 0 3em 0;
    }

    h2 {
        color: $text-navy;
        font-size: 16px;
        font-weight: bold;
        margin: 1em 0;
    }

    p {
        color: $text-navy;
        font-size: 14px;
        margin: 0.75em 0;
    }

    > footer {
        width: 100%;
        margin: 30px 0 0 0;
    }

    .cancel-btn, .ok-btn {
        @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.1));
        @include border-radius(5em);
        border: solid 0.5px $inactive-text-gray;
        font-size: 12px;
        font-weight: normal;

        padding: 8px 25px;
        margin: 10px;
        color: white;
        white-space: nowrap;
        width: calc(100% - 20px);

        border: solid 0.5px $inactive-text-gray;

        @include transition(all 0.333s ease-in-out);

        &:focus {
            @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.3));
        }

        &:disabled {
            opacity: 0.25;
        }

        em {
            font-weight: bold;
            display: block;
            text-transform: uppercase;
        }
    }

    .cancel-btn {
        background-color: #777881;

        &:hover {
            background-color: rgb(119, 120, 129, 0.8)
        }
    }

    .ok-btn {
        border: solid 0.5px $raspberry;
        background-color: $raspberry;
        color: white;

        &:hover {
            background-color: darken($raspberry, 20%);
            border: solid 0.5px darken($raspberry, 20%);
            color: white;
        }
    }
}

