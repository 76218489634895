@import "../../../sass/imports";

.meal-feed-bg {
    background-color: #fafafa;
    // position: relative;

    // --
    // Definition for the background gradient and its animated transition to and from past to present/future
    // --
    // &:before, &:after {
    //     @include transition(all 1s ease-in-out);

    //     opacity: 0;

    //     position: absolute;
    //     z-index: 1;
    //     top: 0; bottom: 0; left: 0; right: 0;
    //     content: ' ';
    //     display: block;
    // }

    // // Background used when the grid is in the past
    // &:before {
    //     background-image: linear-gradient(to right, #253e55, #fcfcfc);
    // }

    // // Background used when the grid is in the present or future
    // &:after {
    //     background-color: #fafafa;
    // }

    // @include max-screen(47em) {
    //     &[data-bg-mode="present"], &[data-bg-mode="future"] {
    //         &:after {
    //             opacity: 1;
    //         }
    //     }

    //     &[data-bg-mode="past"] {
    //         &:before {
    //             opacity: 1;
    //         }
    //     }
    // }

    // @include min-screen(47em) {
    //     &:after {
    //         opacity: 1 !important;
    //     }

    //     &:before {
    //         opacity: 0 !important;
    //     }
    // }

    // > .bg-container {
    //     position: relative;
    //     z-index: 2;
    // }
}

.meal-feed {
    margin: 20px auto 0 auto;
    padding: 110px 0 0 0;
    position: relative;
    width: 100%;

    @supports (padding-top: constant(safe-area-inset-top)) {
        padding-top: calc(110px + constant(safe-area-inset-top));
    }

    @supports (padding-top: env(safe-area-inset-top)) {
        padding-top: calc(110px + env(safe-area-inset-top));
    }

    .feed-scrollable {
        max-width: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
        perspective: 700px;
        will-change: scroll-position;
        -webkit-overflow-scrolling: touch;
        @include transform(translateZ(0));

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .swipable-area {
        position: relative;
        z-index: 1;
        margin-bottom: 15px;
    }

    .feed-table {
        display: table-row;
        margin: 0 20px;
    }

    .next-prev {
        text-align: center;
        height: 40px;
        overflow: visible;
        margin-top: -10px;
        z-index: 2;
        position: relative;
    }

    .next-card-btn,
    .prev-card-btn {
        width: 40px;
        height: 40px;
        background-color: $el-white;
        border: none;
        cursor: pointer;
        @include border-radius(100px);
        @include box-shadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.1));
        @include transition(background-color 0.25s ease-in-out);

        color: $bluey-gray;
        font-size: 17px;

        @include transform(translateY(calc(-50% + 5px)));

        @include min-screen(47em) {
            width: 50px;
            height: 50px;
            font-size: 21px;
            color: $bluey-gray;
            @include transform(translateY(calc(-10% - 5px)));
        }

        &:hover {
            background-color: $el-white;
        }

        &:focus {
            background-color: $el-white;
        }
    }

    .safari-fix {
        @include max-screen(414px) {
            &.next-prev {
                height: 0;
                margin-top: 0px;
                position: initial;
            }

            .next-card-btn,
            .prev-card-btn {
                position: absolute;
                z-index: 10;
                top: 225px;
                width: 45px;
                height: 45px;
                font-size: 21px;
            }

            .next-card-btn {
                right: 6px;
            }

            .prev-card-btn {
                left: 6px;
            }
        }
    }

    .prev-card-btn {
        margin: 0 170px 0 0;

        @include min-screen(47em) {
            margin: 0 145px 0 0;
        }
    }

    .next-card-btn {
    }

    .current-meal-btn-container {
        max-width: 560px;
        margin: 0px auto 5px;
        text-align: center;
        position: relative;
        z-index: 5;
    }

    .current-meal-btn {
        font-size: 8px;
        color: $bluey-gray;
        @include border-radius(20px);
        border: 1px solid $bluey-gray;
        background-color: white;
        padding: 5px 10px;
        text-transform: uppercase;
        margin: 0 16px;
        font-weight: bold;
        letter-spacing: 1px;

        opacity: 0;
        @include transition(opacity 0.25s ease-in-out);

        &[data-visible="true"] {
            opacity: 1;
        }
    }
}

.feed-menu-content {
    text-align: right;
}
