
@import "../../../../sass/imports";

.feed-coachmark-modal {
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;

    &.open {
        .feed-coachmark {
            max-height: calc(100vh - 40px);
            padding: 20px 20px 25px 20px;

            @supports (max-height: constant(safe-area-inset-top)) {
                max-height: calc(100vh - 40px - constant(safe-area-inset-top) - constant(safe-area-inset-bottom));
            }

            @supports (max-height: env(safe-area-inset-top)) {
                max-height: calc(100vh - 40px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
            }
        }
    }
}

.feed-coachmark-modal-container {
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0; width: 100vw;
}

.feed-coachmark {
    overflow: hidden;
    background-color: white;

    position: fixed;
    padding: 0 40px;
    overflow-y: auto;

    bottom: 20px;
    left: 50%;
    width: 90%;
    max-height: 0;
    max-width: 375px;
    @include transition(.333s all ease-in-out);
    @include transform(translate(-50%, 0));
    @include border-radius(8px);

    @include min-screen(47em) {
        bottom: auto;
        top: 50%;
        @include transform(translate(-50%, -50%));
    }
}

.feed-coachmark-styles {
    color: $text-navy;

    h1 {
        font-size: 24px;
        margin: 0 0 20px 0;
        text-align: center;

        img {
            width: 125px;
            vertical-align: -6%;
        }
    }

    h3 {
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        margin: 25px 0 10px 0;
    }

    p {
        position: relative;
        font-size: 16px;
        color: $text-navy;
        line-height: 1.1;
        margin: 0 0 20px 0;
        background-color: #dce4ea;
        padding: 16px 25px;
        @include border-radius(8px);

        &:before {
            position: absolute;
            bottom: 0;
            left: -9px;
            @include icon-font;
            content: $icon-curly-que;
            color: #dce4ea;
        }
    }

    .iframe-container {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 56%;
        margin: 0 0 25px 0;
    }

    iframe, .coachmark-video-preview {
        @include border-radius(12px);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .coachmark-video-preview {
        background: linear-gradient(0deg, rgba(89, 89, 89, 0.6), rgba(89, 89, 89, 0.6)), url(https://static.chewba.info/images/2613d721-170e-49e8-a540-99c88c20de3e.jpg);
        background-size: cover;
        background-position: center;
    }

    .icon-right-arrow {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 62px;
        width: 62px;
        @include transform(translate(-50%, -50%));
        @include border-radius(62px);
        background-color: $orange4;
        color: white;
        font-size: 28px;
        text-align: center;
        padding: 18px 19px;
    }

    footer {
        text-align: center;
    }

    .close-modal-btn {
        width: 100%;
        text-align: center;
        padding: 10px;
        font-size: 14px;
        max-width: 171px;
        color: white;
        background: $raspberry;

        border: none;
        @include border-radius(22px);
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(163, 0, 99, 0.2);
        text-transform: uppercase;
    }
}

