
@import "../../../sass/imports";

.spring-feed {
    overflow: hidden;
    width: 100%;
    min-height: 438px;
    user-select: none;
    -webkit-overflow-scrolling: touch;
    position: relative;
    z-index: 2;

    > .spring-feed-item {
        position: absolute;
        // width: calc(100vw - 80px);
        // max-width: 295px;
        will-change: transform;
    }

    > .spring-feed-item-container {
        width: 100%;
        height: 100%;
        will-change: transform;
        min-height: 295px;
    }
}

