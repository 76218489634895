
@import "../../../sass/imports";

.sub-navigation {
    position: fixed;
    top: 60px;
    left: 50%;
    @include transform(translateX(-50%));
    z-index: 10; // Goes above the half-circle since it doesn't move, but below the header dropdowns.
    width: 100%;
    max-width: 940px;
    margin: auto;
}

.meal-feed-header {
    margin: auto 10px auto 0px;
}

.meal-feed-header-toolbar {
    width: calc(100% - 40px);
    max-width: 940px;
    margin: 0px 20px 10px 20px;
    background-color: white;
    border: solid 0.5px rgba(0, 0, 0, 0.05);
    position: relative;
    z-index: 2;
    white-space: nowrap;
    @include border-radius(4px);
    @include box-shadow(0, 2px, 4px, 0, rgba(0, 0, 0, 0.1));
    display: flex;
    justify-content: space-around;

    > * {
        flex: 1;
    }

    @include min-screen(960px) {
        margin: 0px auto 30px auto;
    }

    .calendar-sync-dropdown, .feed-tools-dropdown {
        width: 100%;
    }

    .grid-toggle-btn,
    .preferences-btn,
    .feed-tools-dropdown > .dropdown-btn,
    .calendar-sync-dropdown > .dropdown-btn,
    .select-meal-type-dropdown > .dropdown-btn,
    .print-grid-btn {
        height: 40px;
        background: none;
        border: none;
        border-left: 1px solid rgba(0, 0, 0, 0.05);
        color: $text-gray;
        vertical-align: top;
        font-size: 15px;
        display: inline-block;
        width: 100%;

        @include transition(all 0.25s ease-in-out);
        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }

        > i {
            display: inline-block;
            vertical-align: middle;
            font-size: 18px;
        }

        > span {
            display: inline-block;
            font-size: 12px;
            margin: 0 0 0 20px;
            vertical-align: middle;

            @include max-screen(768px) {
                display: none;
            }
        }
    }

    .select-meal-type-dropdown[data-state="true"] .dropdown-btn {
        background-color: rgba(0, 0, 0, 0.1);
    }

    .dropdown-btn {
        width: 100% !important;
    }

    .print-grid-btn {

    }
}

.select-meal-type-dropdown {
    display: inline-block;

    .dropdown-btn {
        white-space: nowrap;
    }

    .close-btn {
        display: none;
    }

    .dropdown-container {
        display: block;
    }

    .dropdown-dropdown {
        position: absolute;
        top: calc(100% + 22px);
        left: 50%;
        max-height: 0;
        width: 100vw;
        overflow: hidden;
        @include transform(translate(-50%, 0));
        @include transition(all .25s ease-in-out);
    }

    &[data-state="true"] {
        .dropdown-dropdown {
            opacity: 1;
            max-height: 50em;
            overflow-y: auto;
            display: block;
        }
    }

    &[data-closing="true"] {
        .dropdown-container {
            opacity: 0;
        }
    }

    .triangle, .triangle2 {
        display: none;
    }
}

.select-meal-type {
    // position: absolute;
    // top: calc(100% + 0px);
    // // min-height: 400px;
    // z-index: 10;

    width: 100%;

    .select-meal-scrollable {
        background-color: #fafafa;
        width: 100%;
        padding: 10px;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    &:after {
        display: block;
        content: ' ';
        min-height: 300px;
        width: 100%;
        background-image: linear-gradient(to bottom, #fafafa 53%, rgba(250, 250, 250, 0));
    }

    .select-meal-table {
        display: block;
        text-align: center;
        white-space: nowrap;
        overflow-x: auto;

        > div {
            display: inline-block;
        }
    }

    button {
        border: none;
        background: none;
        text-align: center;
        margin: 5px;
        font-size: 14px;
        color: $text-navy;
        max-width: calc(25vw - 5px);

        &[data-selected="true"] {
            font-weight: bold;

            i {
                @include box-shadow(0, 10px, 20px, 0, rgba(0, 0, 0, 0.2));
                color: white;
                background: $raspberry;
            }
        }
    }

    i {
        display: block;
        font-size: 29px;
        color: $raspberry;
        padding: 25px;
        @include border-radius(10px);
        @include box-shadow(0, 10px, 20px, 0, rgba(0, 0, 0, 0.1));
        background-color: white;
        margin: 0 0 5px 0;

        @include min-screen(455px) {
            font-size: 45px;
        }
    }

    p {
        font-size: 14px;
        color: $text-gray;
        margin: auto;
        max-width: 410px;

        em {
            color: $raspberry;
            text-transform: uppercase;
        }
    }
}


